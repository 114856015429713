import * as React from 'react';
import Container from '@mui/material/Container';
import ResponsiveAppBar from './components/NavBar'
import {Routes, Route} from "react-router-dom"
import Home from './components/Home'
import Cars from './components/Cars'
import Sensors from './components/Sensors'
import Tests from './components/Tests'
import Profile from './components/Profile';
import Account from './components/Account';
import Dashboard from './components/Dashboard';
import Weather from './components/Weather';
import Weather2 from './components/Weather2';
import Graph from './components/Graph'

export default function App() {
 
  return (
    <Container maxWidth="md">
      <ResponsiveAppBar />
      <Routes>
        <Route path = "/" element ={ <Home/>} />
        <Route path = "Home" element ={ <Home/>} />
        <Route path = "Sensors" element ={ <Sensors/>} />
        <Route path = "Cars" element ={ <Cars/>} />
        <Route path = "Graph/:id" element ={ <Graph/>} />
        <Route path = "Tests" element ={ <Tests/>} />

        <Route path = "Account" element ={ <Account/>} />
        <Route path = "Profile" element ={ <Profile/>} />
        <Route path = "Dashboard" element ={ <Dashboard/>} />

        <Route path = "Weather" element ={ <Weather/>} />
        <Route path = "Weather2" element ={ <Weather2/>} />
      </Routes>
    </Container>
  );
}
