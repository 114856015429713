import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { spacing } from '@mui/system';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Stack from '@mui/material/Stack';

import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import ThermostatOutlinedIcon from '@mui/icons-material/ThermostatOutlined';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import WindPowerOutlinedIcon from '@mui/icons-material/WindPowerOutlined';
import UmbrellaRoundedIcon from '@mui/icons-material/UmbrellaRounded';
import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

// https://medium.com/@harshanabatagalla/building-a-weather-forecast-app-with-react-and-openweathermap-api-acb57627118b

const Weather2 = () => {
  const [city, setCity] = useState('zoetermeer');
  const [weatherData, setWeatherData] = useState(null);
  const [forecastData, setForcecastData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.weatherapi.com/v1/forecast.json?q=${city}&days=2&lang=nl&key=c2890501aac34fb5995184123243007`
      );
      setWeatherData(response.data);
      //setForcecastData(response.data.forecast.forecastday[0].hour)
      const newArray = []
      if (response.data) {
        response.data.forecast.forecastday.forEach((day) => {
          //console.log(day)
          day.hour.forEach((hour)=> {
            if (hour.time_epoch >= response.data.location.localtime_epoch - 3600) {
              //console.log(hour)
              if (newArray.length<24)
                newArray.push(hour)
            }
          })
        });        
      }


      //console.log(newArray); //You can see all the weather data in console log
      setForcecastData(newArray)
    } catch (error) {
      console.error(error);
    }
  };

  
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]); // Let op; geen array dan auto zoek bij wijzigen City

  const handleInputChange = (e) => {
    setCity(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };


  //<td>{time}</td>
  //<td><b>{convertEpoch(time_epoch)}</b></td>
  //        <td>{time_epoch}</td>

/*
          {
            forecastData && forecastData.map(ForeCastRowPlain)
          }
*/


  const WeatherTable = (
      <TableContainer component={Paper}>
        <Table  sx={{ minWidth: 250 }} size="small" aria-label="weersvoorspelling">
          <TableHead>
            <TableRow>
              <TableCell><AccessTimeRoundedIcon/></TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"><ThermostatOutlinedIcon/></TableCell>
              <TableCell align="right"><WaterDropOutlinedIcon/></TableCell>
              <TableCell align="right"><CloudOutlinedIcon/></TableCell>
              <TableCell align="center"><ExploreRoundedIcon/></TableCell>
              <TableCell align="right"><WindPowerOutlinedIcon/></TableCell>
              <TableCell align="right"><UmbrellaRoundedIcon /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {forecastData && forecastData.map((row) => (
              <TableRow
                key={row.time_epoch}
                style={{ height : 30}}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Moment date={row.time_epoch*1000} format="DD/MM HH:mm" />
                  
                </TableCell>
                <TableCell align="left"><img alt="condition" title={row.condition?.text} src={row.condition?.icon} height={32} /></TableCell>
                <TableCell align="right">{row.temp_c}</TableCell>
                <TableCell align="right">{row.humidity}</TableCell>
                <TableCell align="right">{row.cloud}</TableCell>
                <TableCell align="center">{row.wind_dir}</TableCell>
                <TableCell align="right">{row.wind_kph}</TableCell>
                <TableCell align="right">{row.precip_mm}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )

  return (
    <div>
      <p></p>
      <Paper component={Stack} sx={{ m: 2, p: 2 }} alignItems="verticalcenter" display="flex" flexDirection="column" >
      <form onSubmit={handleSubmit}>
        <TextField id="outlined-basic" value={city} size="small" placeholder="Vul plaatsnaam in" label="Plaatsnaam" variant="outlined" onChange={handleInputChange} />
        &nbsp;<Button variant="outlined" size="medium" type="submit">Lees weer</Button>
      </form>
      </Paper>
      {weatherData && weatherData.current ? (
        <>
          <h2>{weatherData.location.name}</h2>
          <p>Temperatuur      : {weatherData.current.temp_c}°C</p>
          <p>Voelt als        : {weatherData.current.feelslike_c}°C</p>
          <p>Luchtvochtigheid : {weatherData.current.humidity}%</p>
          <p>Druk             : {weatherData.current.pressure_mb}</p>
          <p>Windsnelheid     : {weatherData.current.wind_kph}m/s</p>
          <p>Conditie         : <img alt="condition" src={weatherData.current?.condition?.icon} height = {30} /> {weatherData.current.condition?.text}</p>
          

          {WeatherTable}

        </>
      ) : (
        <p>Lezen weer data...</p>
      )}
    </div>
  );
};

//               

export default Weather2;