
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';

export const createDefObject = (newItem) => {
  //Do something with the input
  let icon = DeviceThermostatIcon;
  let label = null
  let unit = "\u00B0C"
  let color = 'secondary'
  let color2 = 'primary'
  let limit = 21

  switch (newItem.valueId) {
    case "device_kWh_Sun":
      icon = SolarPowerIcon;
      unit = "kWh"
      label = "Zon"
      color = 'warning'
      limit = 10
      break;
    case "device_zonnepaneel_huidig":
      icon = SolarPowerIcon;
      unit = "W"
      label = "Zon"
      color = 'success'
      color2 = 'warning'
      limit = 10
      break;
    case "device_kWh_Electricity":
      icon = ElectricalServicesIcon;
      label = "Net"
      unit = "kWh"
      color = 'info'
      break;
    case "device_m3_Gas":
      icon = GasMeterIcon;
      unit = "m3"
      label = "Gas"
      color = "success"
      break;
    case "device_kamertemperatuur_ouders":
      icon = DeviceThermostatIcon;
      label = "Ouders"
      unit = "\u00B0C"
      color2 = "error"
      break;
    case "device_kamertemperatuur_woonkamer":
      icon = DeviceThermostatIcon;
      label = "Woonkamer"
      unit = "\u00B0C"
      color2 = "error"
      break;
    case "device_kamertemperatuur_zolder":
      icon = DeviceThermostatIcon;
      label = "Zolder"
      unit = "\u00B0C"
      color2 = "error"
      break;
    case "device_buiten_temperatuur":
      icon = DeviceThermostatIcon;
      label = "Buiten"
      unit = "\u00B0C"
      color2 = "error"
      break;
    case "device_tuin_temperatuur":
      icon = DeviceThermostatIcon;
      label = "Tuin"
      unit = "\u00B0C"
      color2 = "error"
      break;
    case "device_luchtvochtigheid_ouders":
      icon = WaterDropOutlinedIcon;
      label = "Ouders"
      unit = "%"
      break;
    case "device_luchtvochtigheid_woonkamer":
      icon = WaterDropOutlinedIcon;
      label = "Woonkamer"
      unit = "%"
      break;
    case "device_luchtvochtigheid_zolder":
      icon = WaterDropOutlinedIcon;
      label = "Zolder"
      unit = "%"
      break;
  }

  newItem.icon = icon;
  newItem.label = label;
  newItem.unit = unit;
  newItem.color = color;
  newItem.color2 = color2;
  newItem.limit = limit;

  return newItem;
};
