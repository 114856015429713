import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {createDefObject} from '../shared/utils.js'

import { SvgIcon } from "@mui/material";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import {
    useParams,
} from "react-router-dom";


// https://testdouble.com/insights/guide-advanced-line-graphs-in-react-with-mui-x-charts
import { LineChart } from '@mui/x-charts/LineChart';

const Graph = () => {

    const { id } = useParams();
    const [myDataset, setMyDataset] = useState([]);
    const [myTitle, setMyTitle] = useState(id);
    const [defItem,setDefItem] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `https://reacttest.degraafjes.net/api/getgraphdata.php?CORS=1&valueid=${id}`
            );
            //setMyDataset([{x: 1, y: 32}, {x: 5, y: 41}, {x: 15, y: 1}, {x: 25, y: 31}]);
            let newData = [];
            response.data.forEach((item) => {
                let p = {};
                p.x = new Date(item.dt);
                p.y = item.value
                newData.push(p)
            });

            setMyDataset(newData);

            let newItem = {};
            newItem.valueId = id;
            newItem = createDefObject(newItem);
            if (newData.length>0)
              newItem.value = newData[newData.length-1].y

            setDefItem(newItem)
            setMyTitle(newItem.label+' ['+newItem.unit+']')
            console.log(newItem)

            //seriesData[0]=newData;

            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const options = { month: 'numeric', day: 'numeric', hour: "numeric", minute: "numeric" };
    

    return (
        <>
        <Paper component={Stack} sx={{ m: 2, p: 2 }} alignItems="verticalcenter" display="flex" flexDirection="row" >
            {defItem && <SvgIcon component={defItem.icon} color={defItem.value > defItem.limit ?  defItem.color2 : defItem.color1 } sx={{ fontSize: 20 }} />}&nbsp;
            {defItem && defItem.label}&nbsp;
            {defItem && defItem.value.toFixed(2)} 
        </Paper>

            {myDataset &&
            <LineChart
                xAxis={[
                    { dataKey: "x", label: myTitle,  scaleType: 'time', valueFormatter: (v, {dataIndex} ) => `${v.toLocaleDateString("nl-NL",options)}`}
                ]}
                yAxis={[
                    { label: "Temp" }
                ]}
                series={[
                    { dataKey: "y",  label: myTitle,  showMark: false  }
                ]}
                dataset={myDataset}
                height={300}
            />
            }
            
        </>

    )
}

export default Graph

//dataset={myDataset}

/*
            <LineChart
                xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                series={[
                    {
                        data: [2, 5.5, 2, 8.5, 1.5, 5],
                    },
                    {
                        data: [27, 5.5, 82, 8.5, 1.5, 5],
                    },
                ]}
                width={500}
                height={300}
            />

                        <ul>
        {myDataset.map(artist => (
            <li key={artist.x}>{artist.y}</li>
            ))}
        </ul>

                    
            <LineChart
                xAxis={xAxis}
                yAxis={yAxis}
                series={[
                    {type: 'line', label: 'Atlanta, GA', data: seriesData[0]},
                    {type: 'line', label: 'Toronto, ON', data: seriesData[1]},        
                ]}
                
                width={500}
                height={300}
            />
*/