import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { SvgIcon } from "@mui/material";



import IconButton from '@mui/material/IconButton';
import { Link as RouterLink } from 'react-router-dom';

import ShowChartIcon from '@mui/icons-material/ShowChart';

import TrendLine from './shared/trend';
import {createDefObject} from '../shared/utils.js'


const MyCard = ({ data }) => {
  const Icon = data.icon;
  const Color1 = data.color;
  const Color2 = data.color2;
  return (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 9 }} color="text.secondary" gutterBottom>
          Update: {data.dt}
        </Typography>
        <Typography variant="h5" component="div">
          {data.value} {data.unit}  <TrendLine valueId={data.valueId} />
        </Typography>
        <span style={{ alignItems: 'center', display: "flex" }}>
          <SvgIcon component={Icon} color={data.value > data.limit ?  Color2 : Color1 } sx={{ fontSize: 40 }} />
          <Typography variant="body2">
            {data.label ? data.label : data.valueId}
          </Typography>
          <IconButton aria-label="graph" color="secondary" component={RouterLink} to={`/graph/${data.valueId}`}>
              <ShowChartIcon />
          </IconButton>
         
        </span>

      </CardContent>
    </React.Fragment>
  )
};

const Sensors = () => {
  //const [sensorData, setSensorData] = useState(null);
  const [valueData, setValueData] = useState(null);

  // https://mui.com/material-ui/customization/color/
  // https://mui.com/material-ui/customization/palette/
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://reacttest.degraafjes.net/api/getlatest.php?CORS=1`
      );
      //setSensorData(response.data);
      //console.log(response.data);

      const newArray = []
      if (response.data) {
        response.data.forEach((item) => {
          let newItem = { ...item }
          newItem = createDefObject(newItem);
          newArray.push(newItem)
        });
      }
      setValueData(newArray);
      console.log(newArray)
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Let op; geen array dan auto zoek bij wijzigen City



  return (
    <>
      <Grid container margin={2} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {valueData && valueData.map((e) =>
        (
          <Grid key={e.valueId} item xs={6} sm={4} md={3}>
            <Card sx={{ maxWidth: 200 }} variant="outlined"><MyCard variant="outlined" data={e} />
            </Card>
          </Grid>
        ))}

      </Grid>
    </>
  )
}

export default Sensors


/*
    <Box sx={{ minWidth: 275 }} display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2} >
      {sensorData && sensorData.map((e) =>
      (
        <Card sx={{ maxWidth: 275 }} variant="outlined"><MyCard variant="outlined" data={e} /></Card>
      ))}
    </Box>
*/