import React, {useState, useEffect} from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import axios from 'axios';


export default function TrendLine({valueId}) {
    // https://mui.com/material-ui/customization/color/#2014-material-design-color-palettes
    const fetchData = async () => {
        try {
            const response = await axios.get(
                `https://reacttest.degraafjes.net/api/getgraphdata.php?CORS=1&valueid=${valueId}&hours=2`
            );
            //setMyDataset([{x: 1, y: 32}, {x: 5, y: 41}, {x: 15, y: 1}, {x: 25, y: 31}]);
            let newData = [];
            response.data.forEach((item) => {
                newData.push(item.value)
            });

            setTrendData(newData);

            console.log(newData)

        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueId]);

  const [trendData, setTrendData] = useState([]);
  return (
    <Stack direction="row" sx={{ width: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <SparkLineChart data={trendData} height={40} />
      </Box>
    </Stack>
  );
}